

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed,
  getCurrentInstance
} from '@vue/composition-api';
import axios from 'axios';
import store, {
  useUserState,
  useUserActions,
  useProgramActions,
  useProgramState,
  useToolActions
} from '../../../../store';
import { ActionTypes } from '../../../../store/modules/tools/actions';
import { saveScopesToAirtable, loadScopesFromAirtable } from '../../../../services/airtable';

// First define generateDeviceId
const generateDeviceId = () => {
  const storedId = localStorage.getItem('device_id');
  if (storedId) return storedId;

  const newId = `device_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  localStorage.setItem('device_id', newId);
  return newId;
};

// Then define getDeviceInfo which uses generateDeviceId
const getDeviceInfo = () => {
  const storedInfo = localStorage.getItem('device_info');
  if (storedInfo) return JSON.parse(storedInfo);

  // Get browser info
  const { userAgent } = navigator;
  const browser = userAgent.split(' ').pop()?.split('/')[0] || 'unknown';
  const { platform } = navigator;
  const { language } = navigator;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const timestamp = new Date().toISOString();

  // Create device info object
  const deviceInfo = {
    id: generateDeviceId(),
    browser,
    platform,
    language,
    timeZone,
    firstSeen: timestamp
  };

  // Store the device info
  localStorage.setItem('device_info', JSON.stringify(deviceInfo));
  return deviceInfo;
};

export default defineComponent({
  name: 'BulkSetupScopesWizard',
  components: {
    MSelectTemplate: () => import('@/components/molecules/settings/m-select-template.vue'),
    OnboardDialog: () => import('@/components/molecules/m-onboarding.vue')
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { user }: any = useUserState(['user']);
    const {
      createAutomationProgram,
      simulateScope,
      simulateProgramDetails,
      updateAutomationAdk,
      saveBulkScopeProcess
    } = useUserActions([
      'createAutomationProgram',
      'simulateScope',
      'simulateProgramDetails',
      'updateAutomationAdk',
      'saveBulkScopeProcess'
    ]);
    const loading = ref(false);
    const selectedScopes = ref([]);
    const websiteInput = ref('');
    const programLoading = ref(false);
    const newProgramData = reactive({});
    const openProgramTemplate = ref(false);
    const errors = ref(false);
    const selectedScopeBulkData = ref([]);
    const isFromAutoSetupValue = ref(false);
    const instance = getCurrentInstance();
    const root = instance?.proxy;
    const shareLink = ref('');
    const isSharableBtn = ref(false);
    const saveDraftLoading = ref(false);

    // Update the effectiveUserId computed property
    const effectiveUserId = computed(() => {
      // If there's a userId prop and it's not empty, always use that
      if (props.userId && props.userId.trim() !== '') {
        return props.userId;
      }
      // Only fall back to device ID if no valid userId exists
      return generateDeviceId();
    });
    const isOnboarding = ref(false);
    function toggleDialog(val) {
      isOnboarding.value = val;
    }

    // Define dialogs before it's used
    const dialogs = ref({
      strengths: false,
      passions: false,
      problems: false,
      jobs: false,
      careers: false,
      skills: false,
      tools: false,
      deliverables: false,
      goals: false,
      users: false,
      upload: false,
      websites: false
    });

    const dialogInputs = ref({
      strengths: '',
      passions: '',
      problems: '',
      jobs: '',
      careers: '',
      skills: '',
      tools: '',
      deliverables: '',
      goals: '',
      users: '',
      upload: null
    });

    const savedInputs = ref({
      strengths: localStorage.getItem('scope_strengths') || '',
      passions: localStorage.getItem('scope_passions') || '',
      problems: localStorage.getItem('scope_problems') || '',
      jobs: localStorage.getItem('scope_jobs') || '',
      careers: localStorage.getItem('scope_careers') || '',
      skills: localStorage.getItem('scope_skills') || '',
      tools: localStorage.getItem('scope_tools') || '',
      deliverables: localStorage.getItem('scope_deliverables') || '',
      goals: localStorage.getItem('scope_goals') || '',
      user: localStorage.getItem('scope_user') || '',
      upload: localStorage.getItem('scope_upload') || ''
    });

    // Add computed property for checking if any inputs are saved
    const hasAnySavedInputs = computed(() => {
      return Object.values(savedInputs.value).some(value => value !== '');
    });

    const formatWebsiteUrl = (website: string) => {
      return website.startsWith('http') ? website : `https://${website}`;
    };

    // Table headers
    const headers = [
      {
        text: '#',
        align: 'center',
        value: 'index',
        width: '50px',
        sortable: false
      },
      {
        text: 'Scope',
        align: 'start',
        value: 'scope'
      }
    ];

    // Move these declarations before the scopesList definition
    const scopesPerEmployer = ref(1);
    const previousScopesPerEmployer = ref(1);

    // Add computed property for scopesPerEmployerChanged
    const scopesPerEmployerChanged = computed(() => {
      return scopesPerEmployer.value !== previousScopesPerEmployer.value;
    });

    // Then the scopesList definition
    const scopesList = ref([
      {
        id: `amazon.com-0-0-${Date.now()}`,
        index: 1,
        website: 'amazon.com',
        scope:
          'Conduct comprehensive security assessment of authentication mechanisms, session management, and access controls across the e-commerce platform. This includes testing of user authentication flows, password policies, session timeout mechanisms, and privilege escalation vectors in the customer account management system.'
      },
      {
        id: `google.com-1-0-${Date.now()}`,
        index: 2,
        website: 'google.com',
        scope:
          'Evaluate security of API endpoints, OAuth implementations, and third-party integrations across Google Cloud Platform services. Assessment to include penetration testing of API authentication, authorization mechanisms, rate limiting controls, and data validation processes in the development environment.'
      },
      {
        id: `microsoft.com-2-0-${Date.now()}`,
        index: 3,
        website: 'microsoft.com',
        scope:
          'Perform security assessment of Azure cloud infrastructure, focusing on identity management, network security, and compliance controls. Testing to cover Azure Active Directory configurations, network segmentation, encryption implementations, and security baseline compliance across cloud resources.'
      },
      {
        id: `apple.com-3-0-${Date.now()}`,
        index: 4,
        website: 'apple.com',
        scope:
          'Conduct security review of iOS application ecosystem, including App Store security controls, application signing mechanisms, and data protection features. Assessment to include analysis of app sandbox implementation, keychain security, and privacy controls in the iOS development framework.'
      },
      {
        id: `facebook.com-4-0-${Date.now()}`,
        index: 5,
        website: 'facebook.com',
        scope:
          'Assess security of social media platform focusing on user privacy controls, data access permissions, and content delivery networks. Testing to include evaluation of privacy settings, third-party application permissions, and content security policies across the platform.'
      },
      {
        id: `netflix.com-5-0-${Date.now()}`,
        index: 6,
        website: 'netflix.com',
        scope:
          'Evaluate streaming platform security with focus on content protection, DRM implementation, and user authentication systems. Assessment to include testing of video delivery security, subscription management, and regional access controls across streaming infrastructure.'
      },
      {
        id: `twitter.com-6-0-${Date.now()}`,
        index: 7,
        website: 'twitter.com',
        scope:
          'Review security of real-time data processing systems, focusing on tweet delivery infrastructure and user interaction mechanisms. Testing to include assessment of rate limiting, abuse prevention systems, and data privacy controls in the messaging platform.'
      },
      {
        id: `linkedin.com-7-0-${Date.now()}`,
        index: 8,
        website: 'linkedin.com',
        scope:
          'Assess professional network security controls, focusing on connection privacy, messaging systems, and profile data protection. Testing to include evaluation of connection request mechanisms, InMail security, and professional data access controls across the platform.'
      },
      {
        id: `salesforce.com-8-0-${Date.now()}`,
        index: 9,
        website: 'salesforce.com',
        scope:
          'Review CRM platform security focusing on multi-tenant data isolation, API security, and custom application controls. Assessment to include testing of Salesforce Shield implementation, field-level security, and sharing rules across organization boundaries.'
      },
      {
        id: `adobe.com-9-0-${Date.now()}`,
        index: 10,
        website: 'adobe.com',
        scope:
          'Evaluate Creative Cloud service security focusing on asset management, collaboration features, and licensing controls. Testing to include assessment of creative asset protection, team permissions, and subscription management across the Creative Cloud platform.'
      }
    ]);

    const getBulkScopeData = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const scopeId = queryParams.get('id');
      try {
        const API_ENDPOINT = process.env.VUE_APP_AUTOMATE_SETUP_SERVICE;
        const resp = await axios.get(`${API_ENDPOINT}/get-bulk-setup-scope?bulkId=${scopeId}`);
        scopesList.value = resp.data.data.selectedScope;
        websiteInput.value = scopesList.value.map(scope => scope.website).join('\n');
        savedInputs.value = resp.data.data.savedInputs;
        scopesList.value = scopesList.value.map((scope, index) => ({
          ...scope,
          id:
            scope.id ||
            `${scope.website}-${Math.floor(index / scopesPerEmployer.value)}-${
              index % scopesPerEmployer.value
            }-${Date.now()}`,
          index: index + 1
        }));
        selectedScopes.value = [...scopesList.value];
      } catch (err) {
        console.error('Single scope generation error:', err);
        throw err;
      }
    };

    async function alertUser(message, type) {
      if (type === 'error') errors.value = true;
      const { showSnackbar } = useToolActions(['showSnackbar']);
      showSnackbar({
        message,
        type,
        isShowSnackbar: true
      });
    }

    // Initialize selectedScopes with all items from scopesList
    onMounted(() => {
      websiteInput.value = scopesList.value.map(scope => scope.website).join('\n');
      const queryParams = new URLSearchParams(window.location.search);

      if (queryParams.has('sites')) {
        try {
          const sites = JSON.parse(queryParams.get('sites'));

          if (Array.isArray(sites)) {
            scopesList.value = sites.map(site => ({ website: site }));
            websiteInput.value = scopesList.value.map(scope => scope.website).join('\n');
          }
        } catch (error) {
          console.error('Invalid sites parameter:', error);
        }
      }
      scopesList.value = scopesList.value.map((scope, index) => ({
        ...scope,
        id:
          scope.id ||
          `${scope.website}-${Math.floor(index / scopesPerEmployer.value)}-${
            index % scopesPerEmployer.value
          }-${Date.now()}`,
        index: index + 1
      }));
      // Initialize selected scopes
      selectedScopes.value = [...scopesList.value];
      if (queryParams.has('id')) {
        getBulkScopeData();
      }
    });

    const websiteChanges = ref({
      added: [],
      removed: []
    });

    const handleWebsiteInput = (value: string) => {
      const newWebsites = value
        .split('\n')
        .map(site => site.trim())
        .filter(site => site.length > 0);

      // Calculate added and removed websites
      websiteChanges.value = {
        added: newWebsites.filter(site => !scopesList.value.find(scope => scope.website === site)),
        removed: scopesList.value
          .map(scope => scope.website)
          .filter(site => !newWebsites.includes(site))
      };
    };

    // Update saveWebsites function
    const saveWebsites = () => {
      // Get unique websites from current scopesList
      const uniqueWebsites = [...new Set(scopesList.value.map(scope => scope.website))];

      // Add new websites from changes
      uniqueWebsites.push(...websiteChanges.value.added);

      // Remove websites that should be removed
      const filteredWebsites = uniqueWebsites.filter(
        website => !websiteChanges.value.removed.includes(website)
      );

      // Create new scopes list with correct number of scopes per website
      scopesList.value = filteredWebsites.flatMap((website, websiteIndex) => {
        return Array(scopesPerEmployer.value)
          .fill(null)
          .map((_, index) => ({
            id: `${website}-${websiteIndex}-${index}-${Date.now()}`,
            index: websiteIndex * scopesPerEmployer.value + index + 1,
            website,
            scope: `Conduct comprehensive security assessment ${
              index + 1
            } of ${website}, focusing on authentication mechanisms, data protection, and access controls. Testing to include evaluation of user authentication flows, API security, and infrastructure security controls across the platform.`
          }));
      });

      // Update selected scopes
      selectedScopes.value = [...scopesList.value];

      // Reset changes and close dialog
      websiteChanges.value = { added: [], removed: [] };
      previousScopesPerEmployer.value = scopesPerEmployer.value;
      dialogs.value.websites = false;
    };

    const saveBulkScope = async () => {
      if (user?.value) {
        console.log('selected scope', selectedScopes.value);
        saveDraftLoading.value = true;
        const payload = {
          userId: user?.value?._id?.toString(),
          selectedScope: selectedScopes.value,
          savedInputs: savedInputs.value
        };
        const result = await saveBulkScopeProcess(payload);
        if (result.insertedId) {
          saveDraftLoading.value = false;
          isSharableBtn.value = true;
          shareLink.value = `${window.location.origin}${root.$route.path}?id=${result.insertedId}`;
        }
      } else {
        isOnboarding.value = true;
        localStorage.setItem(
          'setRoute',
          `${root.$route.path}?${new URLSearchParams(root.$route.query).toString()}`
        );
      }
    };

    const copyShareLink = async () => {
      try {
        if (shareLink.value) {
          await navigator.clipboard.writeText(shareLink.value);
          alertUser('Share link copied successfully!', 'success');
        } else {
          alertUser('Share link is not available yet!', 'error');
        }
      } catch (error) {
        console.error('Error copying share link:', error);
        alertUser('Failed to copy link to clipboard.', 'error');
      }
    };

    const saveAsdraft = () => {
      if (selectedScopes.value.length) {
        saveBulkScope();
      }
    };

    // Add helper function for save button text
    const getSaveButtonText = () => {
      const parts = [];
      if (websiteChanges.value.added.length) {
        parts.push(`Add ${websiteChanges.value.added.length}`);
      }
      if (websiteChanges.value.removed.length) {
        parts.push(`Remove ${websiteChanges.value.removed.length}`);
      }
      if (scopesPerEmployerChanged.value) {
        parts.push(`Update Scopes`);
      }
      return parts.join(' & ');
    };

    const generateAIPrompt = async website => {
      const requestData = {
        websiteUrl: website.startsWith('http') ? website : `https://${website}`,
        savedInputs: savedInputs.value
      };

      try {
        const API_ENDPOINT = process.env.VUE_APP_AUTOMATE_SETUP_SERVICE;
        const resp = await axios.post(`${API_ENDPOINT}/generate-scope/promt`, requestData);
        // Single scope response has data[0] directly containing the scope text
        const scopeText = resp.data.data[0];
        return scopeText;
      } catch (err) {
        console.error('Single scope generation error:', err);
        throw err;
      }
    };

    const copyAndOpenChatGPT = async item => {
      try {
        const prompt = await generateAIPrompt(item.website);
        console.log(prompt, 'here');
        const index = scopesList.value.findIndex(scope => scope.id === item.id);
        if (index !== -1) {
          scopesList.value[index].scope = prompt.replace(/"/g, '');
        }
      } catch (err) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to generate prompt',
          isShowSnackbar: true
        });
      }
    };

    // First, add these refs at the start of the setup function
    const regeneratingScopes = ref(new Set());
    const regeneratingAll = ref(false);
    async function requestProgramDetails(data) {
      try {
        // const res = await socket.sendMessage({
        //   action: 'details',
        //   data
        // });
        const res = await simulateProgramDetails(data);
        newProgramData.value = res;
      } catch (err) {
        programLoading.value = false;
        console.error('Error while trying to generate program: ', err);
        const messageRegex = /^(?!.*error).*\n(.*)/i;
        const match = err.message.match(messageRegex);
        const output = match[1];
      }
    }

    async function requestProgramUpdate() {
      const newProgram = { ...newProgramData.value };
      try {
        if (newProgramData.value !== 'You are unable to create program') {
          newProgram.programType = 'automation';
          newProgram.userId = user?.value?._id?.toString();
          selectedScopeBulkData.value.push({ ...newProgram });
          isFromAutoSetupValue.value = true;
          if (selectedScopes.value.length === selectedScopeBulkData.value.length) {
            openProgramTemplate.value = true;
            programLoading.value = false;
          }
        } else {
          programLoading.value = false;
          alertUser(newProgramData.value, 'error');
        }
      } catch (err) {
        console.error('Error while trying to update program: ', err);
        programLoading.value = false;
      }
    }

    async function submitScopeHandler(scopeData) {
      if (user?.value) {
        if (
          !user?.value?.userTypes?.includes('Employer') &&
          !user?.value?.userTypes?.includes('Teacher') &&
          !user?.value?.userTypes?.includes('Student')
        ) {
          alertUser('User must be host to create automation program!', 'error');
        } else {
          programLoading.value = true;
          if (!scopeData) {
            alertUser('Please select a program before continuing', 'error');
            programLoading.value = false;
            return;
          }
          scopeData.forEach(async scope => {
            const scopeData = scope.scope ? scope.scope : '';
            if (!scopeData) {
              alertUser('Please apply a scope before continuing', 'error');
              programLoading.value = false;
              return;
            }
            const data = {
              companySite: scope.website,
              programScope: scopeData
            };

            try {
              await requestProgramDetails(data).then(() => requestProgramUpdate());
            } catch (error) {
              console.error('Error processing scope:', scope, error);
            }
          });
          // const { fetchProgramById } = useUserActions(['fetchProgramById']);
          // await fetchProgramById({ program_id: props.programDoc.data._id });
        }
      } else {
        isOnboarding.value = true;
        localStorage.setItem(
          'setRoute',
          `${root.$route.path}?${new URLSearchParams(root.$route.query).toString()}`
        );
      }
    }
    // Update the regenerateScope function
    const regenerateScope = async item => {
      try {
        regeneratingScopes.value.add(item.id);
        const prompt = await generateAIPrompt(item.website);
        const index = scopesList.value.findIndex(scope => scope.id === item.id);
        if (index !== -1) {
          scopesList.value[index].scope = prompt.replace(/"/g, '');
          scopesList.value = [...scopesList.value];
        }
      } catch (err) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to generate prompt',
          isShowSnackbar: true
        });
      } finally {
        regeneratingScopes.value.delete(item.id);
      }
    };

    // First define generateCombinedAIPrompt
    const generateCombinedAIPrompt = async scopes => {
      try {
        const websitesData = scopes.map(scope => ({
          websiteUrl: scope.website.startsWith('http') ? scope.website : `https://${scope.website}`,
          savedInputs: savedInputs.value
        }));

        const API_ENDPOINT = process.env.VUE_APP_AUTOMATE_SETUP_SERVICE;
        const resp = await axios.post(
          `${API_ENDPOINT}/generate-multiple-scope/promt`,
          websitesData
        );

        if (!resp.data?.data) return false;

        resp.data.data.forEach((resData, index) => {
          if (!resData || !Array.isArray(resData)) return;

          const matchingScopeIndex = scopesList.value.findIndex(
            scope => scope.id === scopes[index].id
          );

          if (matchingScopeIndex !== -1) {
            // Multiple scope response has data[][0] containing the scope text
            const scopeText = resData[0];
            if (scopeText) {
              scopesList.value[matchingScopeIndex].scope = scopeText.replace(/"/g, '');
            }
          }
        });

        scopesList.value = [...scopesList.value];
        return true;
      } catch (err) {
        console.error('Multiple scope generation error:', err);
        return false;
      }
    };

    // Then define regenerateAllScopes which uses it
    const regenerateAllScopes = async () => {
      try {
        if (selectedScopes.value.length === 0) return;
        regeneratingAll.value = true;
        await generateCombinedAIPrompt(selectedScopes.value);
      } catch (err) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to generate combined prompt',
          isShowSnackbar: true
        });
      } finally {
        regeneratingAll.value = false;
      }
    };

    const createPrograms = () => {
      if (selectedScopes.value.length) {
        submitScopeHandler(selectedScopes.value);
      }
    };

    // Dialog management functions
    const openDialog = type => {
      dialogs.value[type] = true;
      dialogInputs.value[type] = savedInputs.value[type];
    };

    const closeDialog = type => {
      dialogs.value[type] = false;
      dialogInputs.value[type] = '';
    };

    const saveDialog = type => {
      const input = dialogInputs.value[type];
      if (input.trim()) {
        localStorage.setItem(`scope_${type}`, input);
        savedInputs.value[type] = input;
      } else {
        localStorage.removeItem(`scope_${type}`);
        savedInputs.value[type] = '';
      }
      closeDialog(type);
    };

    const clearDialog = type => {
      localStorage.removeItem(`scope_${type}`);
      savedInputs.value[type] = '';
      closeDialog(type);
    };

    const clearAll = () => {
      Object.keys(savedInputs.value).forEach(key => {
        localStorage.removeItem(`scope_${key}`);
        savedInputs.value[key] = '';
      });
    };

    // Update the employerCount computed property
    const employerCount = computed(() => {
      // Get unique websites by using Set
      const uniqueEmployers = new Set(scopesList.value.map(scope => scope.website));
      return uniqueEmployers.size;
    });

    const getDomainName = website => {
      return website.split('.')[0].toUpperCase();
    };

    const getDomainExtension = website => {
      return `.${website.split('.').slice(1).join('.').toUpperCase()}`;
    };

    // Add this to your setup function
    const editingScope = ref(null);
    const editedScopeText = ref('');

    const editScope = item => {
      editingScope.value = item.id;
      editedScopeText.value = item.scope;
    };

    const saveEditedScope = item => {
      const index = scopesList.value.findIndex(scope => scope.id === item.id);
      if (index !== -1) {
        scopesList.value[index].scope = editedScopeText.value;
      }
      editingScope.value = null;
      editedScopeText.value = '';
    };

    const cancelEdit = () => {
      editingScope.value = null;
      editedScopeText.value = '';
    };

    const saveNameDialog = ref(false);
    const saveName = ref('');
    const saveEmail = ref('');

    // Modify the saveToAirtable function
    const saveToAirtable = async () => {
      try {
        // Set default name
        saveName.value = `Save from ${new Date().toLocaleDateString()}`;
        // Show dialog
        saveNameDialog.value = true;
      } catch (err) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to save customizations',
          isShowSnackbar: true
        });
      }
    };

    // Add new function to handle the actual saving
    const confirmSave = async () => {
      try {
        loading.value = true;
        const saveData = {
          userId: effectiveUserId.value,
          email: saveEmail.value,
          deviceInfo: getDeviceInfo(),
          savedAt: new Date().toISOString(),
          savedInputs: savedInputs.value,
          scopesList: scopesList.value,
          scopesPerEmployer: scopesPerEmployer.value,
          selectedScopes: selectedScopes.value,
          name: saveName.value
        };

        await saveScopesToAirtable(saveData);
        saveNameDialog.value = false;

        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'success',
          message: 'Saved customizations to Airtable',
          isShowSnackbar: true
        });
      } catch (err) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to save customizations',
          isShowSnackbar: true
        });
      } finally {
        loading.value = false;
      }
    };

    const savedScopesDialog = ref(false);
    const savedScopes = ref([]);

    // Modify loadFromAirtable
    const loadFromAirtable = async () => {
      try {
        loading.value = true;
        const saves = await loadScopesFromAirtable(effectiveUserId.value); // Use computed value here
        if (saves.length === 0) {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            type: 'info',
            message: 'No saved customizations found',
            isShowSnackbar: true
          });
          return;
        }
        savedScopes.value = saves;
        savedScopesDialog.value = true;
      } finally {
        loading.value = false;
      }
    };

    // Add new function
    const loadSelectedSave = save => {
      Object.entries(save.savedInputs).forEach(([key, value]) => {
        savedInputs.value[key] = value;
        localStorage.setItem(`scope_${key}`, value);
      });
      scopesList.value = save.scopesList;
      scopesPerEmployer.value = save.scopesPerEmployer;
      selectedScopes.value = save.selectedScopes;
      websiteInput.value = [...new Set(save.scopesList.map(scope => scope.website))].join('\n');
      savedScopesDialog.value = false;
    };

    const websites = computed(() => {
      return [...new Set(scopesList.value.map(scope => scope.website))];
    });

    const handleFileUpload = async event => {
      try {
        const File = event;
        console.log('files', File);
        if (!File || !File.length) return;

        const file = File[0];
        const text = file.name;
        dialogInputs.value.upload = text;
      } catch (err) {
        console.error('Error handling file upload:', err);
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Failed to upload file',
          isShowSnackbar: true
        });
      }
    };

    return {
      loading,
      headers,
      scopesList,
      selectedScopes,
      websiteInput,
      handleWebsiteInput,
      formatWebsiteUrl,
      regenerateScope,
      createPrograms,
      dialogs,
      dialogInputs,
      savedInputs,
      hasAnySavedInputs,
      openDialog,
      closeDialog,
      saveDialog,
      clearDialog,
      clearAll,
      regenerateAllScopes,
      employerCount,
      websiteChanges,
      saveWebsites,
      scopesPerEmployer,
      scopesPerEmployerChanged,
      getSaveButtonText,
      getDomainName,
      getDomainExtension,
      editingScope,
      editedScopeText,
      editScope,
      saveEditedScope,
      cancelEdit,
      copyAndOpenChatGPT,
      generateAIPrompt,
      generateCombinedAIPrompt,
      saveToAirtable,
      loadFromAirtable,
      websites,
      handleFileUpload,
      savedScopesDialog,
      savedScopes,
      loadSelectedSave,
      saveNameDialog,
      saveName,
      confirmSave,
      effectiveUserId,
      saveEmail,
      regeneratingScopes,
      regeneratingAll,
      programLoading,
      openProgramTemplate,
      isFromAutoSetupValue,
      selectedScopeBulkData,
      isOnboarding,
      toggleDialog,
      saveAsdraft,
      isSharableBtn,
      copyShareLink,
      saveDraftLoading
    };
  }
});
